/* schedule-calendar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(152, 152, 152);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(81, 80, 80);
}

.message-counter.badge {
  min-width: 14px;
  position: absolute;
  // right: -5px;
  top: -5px;
  font-size: 10px;
  height: 14px;
  width: auto;
  left: 10px;
}

:root {
  --background-color: #f5f8fa;
  /* Light theme */
}

[data-theme="dark"] {
  --background-color: #2b2b40;
  /* Dark theme */
}

.partner-details {
  margin-bottom: 0.5rem !important;
  padding: 0.3rem !important;
  border: 1px solid transparent;
  border-radius: 5px;

  >div {
    margin-left: 15px !important;
  }
}

.verified {
  display: flex;
  align-items: center;
}

.partner-modal {
  min-height: 650px !important;

}

.svg-icon-primary {
  color: #49ace3 !important;
}

.no-result {
  margin-top: 3rem;
}

// /* GRID */
// .calendar-row {
//   margin: 0;
//   padding: 0;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   width: 100%;
// }

// .row-middle {
//   align-items: center;
// }

// .calendar-col {
//   flex-grow: 1;
//   flex-basis: 0;
//   max-width: 100%;
// }

// .col-start {
//   justify-content: flex-start;
//   text-align: left;
// }

// .col-center {
//   justify-content: center;
//   text-align: center;
// }

// .col-end {
//   justify-content: flex-end;
//   text-align: right;
// }

// /**/

// $color_1: var(--main-color);
// $color_2: var(--kt-gray-800);
// $color_3: var(--kt-light);
// $color_4: #ff1a79;

// /* .schedule-calendar .body .row:last-child {
//   border-bottom: none;
// } */
// .schedule-calendar {
//   display: block;
//   position: relative;
//   width: 100%;
//   background: var(--neutral-color);
//   border: 1px solid var(--kt-border-color);

//   .header {
//     text-transform: uppercase;
//     font-weight: 700;
//     font-size: 115%;
//     padding: 1.5em 0;
//     border-bottom: 1px solid var(--kt-border-color);

//     .icon {
//       cursor: pointer;
//       transition: 0.15s ease-out;

//       &:hover {
//         transition: 0.25s ease-out;
//         color: $color_1;
//       }

//       &:first-of-type {
//         margin-left: 1em;
//       }

//       &:last-of-type {
//         margin-right: 1em;
//       }
//     }
//   }

//   .days {
//     text-transform: uppercase;
//     font-weight: 400;
//     color: $color_2;
//     font-size: 70%;
//     padding: 0.75em 0;
//     border-bottom: 1px solid var(--kt-border-color);
//   }

//   .body {
//     .cell {
//       position: relative;
//       height: 4em;
//       border-right: 1px solid var(--kt-border-color);
//       overflow: hidden;
//       cursor: pointer;
//       background: var(--neutral-color);
//       transition: 0.25s ease-out;
//       font-size: 1.5em;

//       &:hover {
//         background: var(--bg-color);
//         transition: 0.5s ease-out;

//         .bg {
//           opacity: 0.05;
//           transition: 0.5s ease-in;
//         }
//       }

//       &:last-child {
//         border-right: none;
//       }

//       .number {
//         position: absolute;
//         font-size: 82.5%;
//         line-height: 1;
//         top: 0.75em;
//         right: 0.75em;
//         font-weight: 700;
//       }

//       .bg {
//         font-weight: 700;
//         line-height: 1;
//         color: $color_1;
//         opacity: 0;
//         font-size: 8em;
//         position: absolute;
//         top: -0.2em;
//         right: -0.05em;
//         transition: 0.25s ease-out;
//         letter-spacing: -0.07em;
//       }
//     }

//     .selected {
//       border-left: 10px solid transparent;
//       border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
//       border-image-slice: 1;

//       .bg {
//         opacity: 0.05;
//         transition: 0.5s ease-in;
//       }
//     }

//     .today {
//       border-left: 10px solid transparent;
//       border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
//       border-image-slice: 1;
//     }

//     .row {
//       border-bottom: 1px solid var(--kt-border-color);
//     }

//     .disabled {
//       color: $color_3;
//       pointer-events: none;
//     }

//     .cell.today {
//       .bg {
//         color: $color_4;
//         opacity: 0.05;
//       }
//     }

//     .col {
//       flex-grow: 0;
//       flex-basis: calc(100% / 7);
//       width: calc(100% / 7);
//     }
//   }
// }

/* schedule-calendar */

/* GRID */
.calendar-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.calendar-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/**/

// $color_1: var(--main-color);
// $color_2: var(--kt-gray-800);
// $color_3: var(--kt-light);
// $color_4: #ff1a79;

/* .schedule-calendar .body .row:last-child {
  border-bottom: none;
} */
.schedule-calendar {
  display: block;
  position: relative;
  width: 100%;
  // background: var(--neutral-color);
  // border: 1px solid var(--kt-border-color);

  .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    // border-bottom: 1px solid var(--kt-border-color);

    .icon {
      cursor: pointer;
      transition: 0.15s ease-out;

      &:hover {
        transition: 0.25s ease-out;
        // color: $color_1;
      }

      &:first-of-type {
        margin-left: 1em;
      }

      &:last-of-type {
        margin-right: 1em;
      }
    }
  }

  .days {
    text-transform: uppercase;
    font-weight: 400;
    // color: $color_2;
    font-size: 70%;
    padding: 0.75em 0;
    // border-bottom: 1px solid var(--kt-border-color);
  }

  .body {
    .cell {
      position: relative;
      height: 4em;
      // border-right: 1px solid var(--kt-border-color);
      overflow: hidden;
      cursor: pointer;
      background: var(--neutral-color);
      transition: 0.25s ease-out;
      font-size: 1.5em;
      // border-left: 10px solid transparent;

      // &.has-schedule {
      //   border-bottom: 2px solid var(--kt-primary);

      //   .past {
      //     border-bottom: 2px solid var(--kt-gray-400);
      //   }
      // }

      &:hover {
        background: var(--bg-color);
        transition: 0.5s ease-out;

        .bg {
          opacity: 0.05;
          transition: 0.5s ease-in;
        }
      }

      &:last-child {
        border-right: none;
      }

      .number {
        position: absolute;
        font-size: 82.5%;
        line-height: 1;
        top: 0.75em;
        right: 0.75em;
        font-weight: 700;
      }

      .bg {
        font-weight: 700;
        line-height: 1;
        // color: $color_1;
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }

      .check-icon {
        position: absolute;
        bottom: 0;
        right: 8px;
      }

      .num-hours {
        position: absolute;
        bottom: 0;
        right: 8px;
        display: none;
      }
    }

    .selected {
      // border-left: 10px solid transparent;
      border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
      border-image-slice: 1;

      .bg {
        opacity: 0.05;
        transition: 0.5s ease-in;
      }
    }

    .past {
      .check-icon {
        .text-success {
          color: 'lightgray' !important;
        }
      }
    }

    .today {
      // border-left: 10px solid transparent;
      // border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
      // border-image-slice: 1;
      // border-bottom: 2px solid #ff1a79;
    }

    .row {
      // border-bottom: 1px solid var(--kt-border-color);
    }

    .disabled {
      // color: $color_3;
      pointer-events: none;
    }

    .cell.today {
      .bg {
        // color: $color_4;
        opacity: 0.05;
      }
    }

    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}

.calendar-row {
  .past {
    .check-icon {
      .css-4negh2-MuiSvgIcon-root {
        color: #808080 !important;
      }
    }
  }

}



.messages-input-box {
  border: 1px solid aliceblue;
  padding: 0;

  //border-radius: 0.475rem;

  .image-title {
    padding-left: 1.6rem !important;
  }

  .form-control {
    &.input-title {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;

      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.75rem;
      padding-right: 1.5rem;
      z-index: 1;

      top: 12px;

      //border-bottom: 1px solid #eee;
    }

    &.input-text {
      resize: none;
    }

    &:hover {
      ~div {
        .input-textarea {
          >textarea {
            // background-color: '#f5f8fa ';
            // border-color: $input-hover-bg;
          }
        }
      }
    }

    &:focus {
      ~div {
        .input-textarea {
          >textarea {
            // background-color: '#f5f8fa';

            // background-color: $input-focus-bg !important;
            // border-color: $btn-light-border !important;
          }
        }
      }
    }
  }

  &.has-input-title {
    .input-textarea {
      >textarea {
        padding-left: 1.75rem;
        padding-top: 2.75rem;
      }
    }
  }

  .input-textarea {
    >textarea {
      background-color: #f5f8fa;
      border: 2px solid rgb(114, 180, 238);
      color: gray; // border: 2px solid var(--kt-input-solid-bg);
      width: 100%;
      resize: none;
      padding: 1rem 1.5rem;
      // color: var(--kt-input-solid-color);
      border-radius: 0.475rem;
      min-height: 100px;

      &:hover {
        // background-color: '#f5f8fa !important';
        // border-color: $input-hover-bg;
      }

      &.react-autosuggest__input--focused {
        // background-color: '#f5f8fa !important';
        // border-color: $btn-light-border !important;
      }
    }
  }
}

.menu-dropdown-button {
  background-color: #f5f8fa !important;
  color: black !important;
  padding-right: 2.5rem !important;
  text-align: left;
  border: 2px solid aliceblue !important;
  max-height: 45px;
  line-height: 1;

  >i {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: lightgrey !important;
  }

  &.select-offers {
    width: 100%;
  }

  &.btn:focus:not(.btn-active) {
    // background-color: var(--kt-input-solid-bg) !important;
  }

  &.btn:hover:not(.btn-active) {
    // background-color: $input-hover-bg !important;
    // border-color: $input-hover-bg !important;
  }
}

$input-focus-bg: #f5f8fa;
$btn-light-border: #49ace3;

.is-open {
  .css-13cymwt-control {
    display: none;
  }

  .menu-dropdown-button {
    height: "40px";
    background-color: $input-focus-bg !important;
    border-color: $btn-light-border !important;

    &.btn:hover:not(.btn-active),
    &.btn:focus:not(.btn-active) {
      background-color: $input-focus-bg !important;
      border-color: $btn-light-border !important;
    }
  }
}

.change-security-pin {
  >div {
    justify-content: center !important;

    input {
      width: 45px !important;
      height: 45px !important;
      font-weight: bolder;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      font-size: 1.5rem;
      padding: 0 !important;
    }

    input:not([value=""]),
    input:not([value=""]):hover {
      background-color: #f5f8fa !important;
      border-color: #49ace3;
    }
  }
}

// .has-images {
//   position: relative;

//   a {
//     width: 50%;
//     padding: 2px;

//     img {
//       width: 100% !important;
//       margin-bottom: 0;
//       border: 1px solid #e5e5e5;
//     }

//     &:only-child {
//       width: 100%;
//     }
//   }

// .more-images {
//   background-color: rgba(73, 173, 227, 0.8);
//   position: absolute;
//   right: 0;
//   bottom: 9px;
//   width: calc(50% - 12px);
//   height: 125px;
//   border-radius: 5px;
//   margin-right: 9px;
//   color: #ffffff;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 1.6rem;
//   font-weight: 500;
//   cursor: pointer;
// }
// }

.more-images {
  background-color: rgba(73, 173, 227, 0.8);
  position: absolute;
  right: 0;
  bottom: 9px;
  width: calc(50% - 12px);
  height: 125px;
  border-radius: 5px;
  margin-right: 9px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
}

.messages-input-box {
  //border: 1px solid var(--kt-input-border-color);
  padding: 0;

  //border-radius: 0.475rem;
  // background-color: var(--kt-input-solid-bg);
  .image-title {
    padding-left: 1.6rem !important;
  }

  .form-control {
    &.input-title {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;

      position: absolute;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.75rem;
      padding-right: 1.5rem;
      z-index: 1;

      top: 12px;

      //border-bottom: 1px solid #eee;
    }

    &.input-text {
      resize: none;
    }

    &:hover {
      ~div {
        .input-textarea {
          >textarea {
            // background-color: $input-hover-bg !important;
            // border-color: $input-hover-bg;
          }
        }
      }
    }

    &:focus {
      ~div {
        .input-textarea {
          >textarea {
            background-color: $input-focus-bg !important;
            border-color: $btn-light-border !important;
          }
        }
      }
    }
  }

  &.has-input-title {
    .input-textarea {
      >textarea {
        padding-left: 1.75rem;
        padding-top: 0.75rem;
      }
    }
  }

  .input-textarea {
    >textarea {
      background-color: #f5f8fa !important;
      border: 0px solid lightblue !important;
      width: 100%;
      resize: none;
      padding: 1rem 1.5rem;
      color: gray;
      border-radius: 0.475rem;
      min-height: 100px;

      &:hover {
        // background-color: $input-hover-bg !important;
        // border-color: $input-hover-bg;
      }

      &.react-autosuggest__input--focused {
        background-color: $input-focus-bg !important;
        border-color: $btn-light-border !important;
      }
    }
  }
}

.custom-tabs .MuiTabs-flexContainer {
  display: block;
  /* Remove the flex class */
  /* Add any additional styles or overrides for the flex container here */
}

@mixin automated-message-wrapper {
  display: flex;
  inset: 0px 0px -40px;
  padding-bottom: 40px;
}

.automated-messages-wrapper {
  // background-color: var(#49ace3);
  // border-top: 1px solid var(--kt-card-border-color);
  padding: 7px 20px 15px 10px;
  // position: absolute;
  // top: -44px;
  width: 100%;
  // left: 0;
  overflow: hidden;

  .featured-messages-list {
    position: relative;
    height: 32px;

    >.messages-content {
      .filtered-messages {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        &.has-files {
          .text-messages {
            min-width: calc(100% - 100px);
          }
        }

        &.no-file-message {
          .files-messages {
            display: none;
          }

          .text-messages {
            width: 100%;
          }
        }

        &.no-text-message {
          .text-messages {
            display: none;
          }

          .files-messages {
            min-width: 100%;
            left: 0;
            right: auto;
          }
        }

        .text-messages {
          @include automated-message-wrapper;
          //width: calc(85% - 5px);
          // position: absolute;
          // left: 0;
          overflow: auto hidden;
          min-width: 100%;

          a {
            &.no-title {
              line-height: 2;
            }
          }
        }

        .files-messages {
          @include automated-message-wrapper;
          // width: auto;
          // position: absolute;
          // right: 0;
          // left: auto;

          width: fit-content;
          margin-left: 5px;

          &.has-multiple-images {
            overflow: auto hidden;
            width: 100%;
            max-width: 100px;
          }

          &.is-loaded {
            opacity: 1;
          }

          a {
            margin-right: 4px;
          }
        }

        a {
          display: block;
          white-space: nowrap;
          padding: 2px 4px;
          background-color: var(--kt-primary-light);
          border-radius: 3px;
          margin-right: 6px;
          cursor: pointer;
          height: 32px;
          line-height: 1;

          &:last-child {
            margin-right: 0;
          }

          // color: var(--bs-link-hover-color);

          >strong {
            font-size: 11px;
            font-weight: 600;
            display: block;
            margin-bottom: 1px;
            color: var(--kt-text-dark);
          }

          img {
            max-height: 16px;
          }

          &.no-title {
            line-height: 1.8;

            img {
              max-height: 24px;
            }
          }
        }
      }
    }
  }
}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 95%;
  width: calc(100%);
  border: skyblue;
  background-color: white !important;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-radius: 6px;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  color: gray;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 15px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: lightgray !important;
}

// body[style="padding-right: 10px; overflow: hidden;"] {
//   overflow: scroll !important;

//   #simple-popover {
//     position: absolute !important;
//   }
// }

body .edit-virtual-assistant-modal {
  position: relative;
  left: 0;
  transform: inherit;
  margin: 30px auto;
  top: 0;
}

.css-79ws1d-MuiModal-root {
  overflow-y: scroll;
}

// .assigned-va-box {
//   margin-left: 10px;
// }

.message-counter.badge {
  min-width: 14px;
  position: absolute;
  // right: -5px;
  top: -5px;
  font-size: 10px;
  height: 14px;
  width: auto;
  left: 10px;
}

.notifications-count {
  &.badge {
    font-size: 9px;
  }

  &.badge-circle.badge-danger {
    width: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 18px;
  }

  &.position-absolute {
    top: 4px;
    right: 4px;
  }
}

.icon-chat-status.svg-icon.svg-icon-muted {
  color: var(--kt-text-muted) !important;
}

.icon-chat-status.svg-icon.svg-icon-primary {
  color: var(--kt-text-primary) !important;

  &.highlight-danger {
    color: var(--kt-text-danger) !important;
  }
}

body {
  .two-image {
    a:nth-of-type(2) {
      >img {
        margin-left: 4px !important;
      }
    }
  }

  .three-images {
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    max-width: 408px !important;

    img {
      max-width: 100%;
      object-fit: cover;
    }

    a:nth-of-type(2) {
      >img {
        margin-left: 4px !important;
      }
    }

    >a:last-child {
      width: 50% !important;

      >img {
        width: 100% !important;
      }
    }
  }
}

body {
  .upload-images-wrap {
    // display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // max-width: 340px;
    text-align: left;

    a {
      width: 50%;
      padding: 2px;
      position: relative;

      img {
        width: 100% !important;
        height: 100% !important;
      }

      small {
        &.more-images {
          width: calc(100% - 2px);
          margin: 0;
          height: calc(100% - 2px);
          bottom: 0;
          border-radius: 0;
        }
      }

      &:only-child {
        width: 100%;
      }
    }
  }
}

.btn-style {

  background-color: rgba(129, 123, 123, 0.181) !important;
  color: rgb(68, 64, 64) !important;
}

.share-by {
  display: flex !important;
  justify-content: end !important;
}

.css-1uhqabe-MuiStack-root> :not(style)+ :not(style) {
  margin: 0 !important;
}

.css-5sk4s-MuiTabs-root .MuiButtonBase-root:first-of-type {
  padding: 0;
}

.partner-feedback {
  text-align: center !important;
}

.sharedby-cursor {
  cursor: pointer !important;
}


.td-payment-method {
  >span:first-child {
    min-width: 130px;
    display: inline-block;
  }

  >span:last-child {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.amount-inner-wrapper {
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
  flex-wrap: wrap !important;

  .amount-inner {
    .ms-2 {
      margin-left: 0 !important;
    }

    .crypto-symbol {
      margin-bottom: 0 !important;
    }
  }

  .usd {
    width: 100%;
  }
}

.p2p-buyer {
  margin-top: 1px !important;
}

.partner-popup {
  margin-top: -8px !important;
  font-size: 13px !important;
}

.inner_wrapper {
  >div {
    >.symbol {
      display: none;
    }

    .has-platform-logo {
      display: none;
    }
  }

  .common-email-wrapper {
    display: none;
  }
}

.share-bg {
  background-color: rgb(131, 16, 16);
}

.crypto-symbol{
  margin-right: 3px;
  > span{
    top: 4px !important;
  }
  
}



.trans-badge{
     display: flex;
     justify-content: end;
     margin-bottom: 0.2rem;
 }

.partner-details{
  .trade-partner-icon{
    position: relative;
    top: 3px;
   }
   .email-icon{
    position: relative;
    top: 5px;
    margin-right: 2px;
   }
   .phone-icon{
    position: relative;
    top: 2.2px;
   }
   .address-icon{
    position: relative;
    top: 3px;
   }
   .id-icon{
    position: relative;
    top: 3.2px;
   }
}

.transaction-address{
  .txn-address{
    display: block !important;
  }
}

@media screen and (max-width: 1440px) {

  .partner-modal {
    max-height: 350px !important;
    overflow-y: scroll !important;
  }
  .trade-summary-profile{
    display: block !important;
  }
}

@media screen and (max-width: 1360px) {
  .edit-virtual-assistant-modal {
    max-height: 600px;
    overflow-y: auto;
  }
}

@media (max-width: 1360px) {
  .assigned-va-box {
    // margin-top: 8px;
    // margin-left: 0;
  }
}